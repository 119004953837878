<template>
    <section class="section section_type_form-page is_new-design">
        <div class="section__wrapper wrapper">
            <!--<div class="section__logo-wrap">
                <img :src="customize.login_logo">
            </div>-->
            <div class="section__form-wrap">
                <form class="section__form pfull__52" @submit.prevent>
                    <h1 class="section__title mb__16">
                        {{ $t('Reset password') }}
                    </h1>

                    <h3 class="form-subtitle mb__24">{{ $t('Enter the email associated with your account') }}</h3>

                    <FieldGroup_input
                        :field-group_class="'mb__16'"
                        :id="'email'"
                        :label="$t('Email')"
                        :placeholder="$t('Enter your Email')"
                        :error="errors.email"
                        v-model.trim="email"
                    />

                    <button class="button button_view_base button_theme_primary button_size_lg"
                            @click.prevent="onSubmit"
                    >
                        {{ $t('buttons.Confirm') }}
                    </button>

                    <div class="section__horizontal-line horizontal-line"
                         v-if="false"
                    >
                        <div></div>
                        <router-link class="section__link link" to="/resetPassword">{{ $t('Reset password') }}</router-link>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from "vuex";
    import { required, email } from 'vuelidate/lib/validators'

    export default {
        name: 'SectionRequestPasswordReset',
        props: [],
        data() {
            return {
                email: '',
                errors: {},
            }
        },
        components: {},
        validations: {
            email: { required, email },
        },
        computed: {
            ...mapGetters([
                "apiToken"
            ]),
        },
        methods: {
            onSubmit() {
                //console.log('onSubmit', this)
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.errors = {}
                    if(!this.$v.email.required) {
                        //this.errors = {...this.errors, email: 'Email cannot be blank'}
                        this.errors.email= this.$t('Email cannot be blank')
                    }else
                    if(!this.$v.email.email){
                        //this.errors = {...this.errors, email: 'Enter valid email'}
                        this.errors.email= this.$t('Enter valid email')
                    }
                    return false // stop here if form is invalid
                }
                this.$api.auth.forgotPassword(this.email)
                .then((response) => {
                    //console.log(response);
                    if(response.data && !response.data.error){
                        this.errors = {}
                        this.$router.push('/resetPassword')
                    }else{
                        console.warn(response.data);
                        this.errors = {...response.data.errors}
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.errors = {email: ' '}
                });
            }
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>